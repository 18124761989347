import axios from "axios";
export const url = process.env.REACT_APP_API_URL;
export const mtoken = localStorage.getItem('mtoken');

export async function firebase_reg() {

    let data = JSON.stringify({
        "web": {
            "endpoint": "https://fcm.googleapis.com/fcm/send/fbZIG6qMaWQ:APA91bHUQZR0HOZFB4uBrGQrb33pZAUrPXVxJTv3JERu45QihqntyaPUtDiE35SFi_gg9RZeEDHemLYfuPfYoKX6kkpiA5PYjasnog2WsgpF1i5ak7oQjfpptVaWk6obHHHwvOXIWV17",
            "auth": "QmMCNhhmLxIgwr1ayIQKbA",
            "p256dh": "BCR5PpYKnT1Igb0L-kRIIOll2bm6N5QLmyPLgnPGBX_T42vP8r9dXp2mTdDT9Jrre-0g-f1QInj4RIcxBo58CG4",
            "applicationPubKey": "BI6xHDRyreO7Sj-XBnVIQTdq_bZMtIWBKj6mY2DSkOjWQE1rWGBelF47Di-DMPC7GnLLprbc3W1UPHpQwx0cZFg"
        }
    });

    let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: 'https://fcmregistrations.googleapis.com/v1/projects/surajmalmatrimoni/registrations',
        headers: {
            'X-Goog-Api-Key': 'AIzaSyAi8iSiUoIHgDrU9ct4nowfW5WwXsirb5I',
            'X-Goog-Firebase-Installations-Auth': 'FIS eyJhbGciOiJFUzI1NiIsInR5cCI6IkpXVCJ9.eyJhcHBJZCI6IjE6NTQwNzg3NjUzMzE0OndlYjowN2YxY2FhNmE0OTJlMjgwN2Y5OTViIiwiZXhwIjoxNzEwNDIyMzQ5LCJmaWQiOiJkWi0zUjVIWlpNUDdzTHBkQU9BalZKIiwicHJvamVjdE51bWJlciI6NTQwNzg3NjUzMzE0fQ.AB2LPV8wRgIhAIlB36jYcBti5oG95K-K-bN136h2JFIB3Tf1rvJ9zJcTAiEAglaAxhAEuyed5Dai8yOpE7C2mfuq3niB0npNvgdHB1M',
            'Content-Type': 'application/json'
        },
        data: data
    };

    const res = axios.request(config);
    return res.token;
}

export async function login(email, password) {

    try {
        const fcmToken = await firebase_reg();
        const response = await axios.post(`${url}/api/app/users/login`, {
            "email": email,
            "password": password,
            "isOAuth": false
        }, {
            headers: {
                'Authorization': 'MatrimonyAndroid|',
                'DeviceInfo': '{"app":"MatrimonyAndroid","deviceId":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36","fcmToken":"' + fcmToken + '","deviceManufacturer":"Google Inc.","deviceModel":"Mozilla"}',
                'Content-Type': 'application/json'
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export async function register(email, password, phone) {
    const fcmToken = await firebase_reg();

    try {
        const response = await axios.post(`${url}/api/app/users/signUp`, {
            "email": email,
            "password": password,
            "contactNo": phone
        }, {
            headers: {
                'DeviceInfo': '{"app":"MatrimonyAndroid","deviceId":"Mozilla/5.0 (Macintosh; Intel Mac OS X 10_15_7) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/122.0.0.0 Safari/537.36","fcmToken":"' + fcmToken + '","deviceManufacturer":"Google Inc.","deviceModel":"Mozilla"}',
                'Content-Type': 'application/json',
                'Authorization': 'MatrimonyAndroid|',
            }
        });
        return response.data;
    } catch (error) {
        console.log(error)
    }

}

export async function get_admin_system_flag() {
    await axios.post(`${url}/api/admin/systemflags/getAdminSystemFlag`).then((resp) => {
        console.log(resp)
    })
}
export async function getMasterData() {
    try {
        const res = await axios.post(`${url}/api/app/users/getMasterData`);
        return res.data;
    } catch (error) {
        console.log(error)
        return [];
    }

}
export async function view_user_details(id) {
    try {
        const response = await axios.post(`${url}/api/app/users/viewUserDetail`, {
            id: id
        },
            {
                headers: {
                    Authorization: `MatrimonyAndroid|Bearer ${mtoken}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }

}
export async function search_profiles(token, data) {
    try {
        const response = await axios.post(`${url}/api/app/users/searchUser`, data,
            {
                headers: {
                    Authorization: `MatrimonyAndroid|Bearer ${token}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export async function verify_contact_details(email, mobile) {
    // const fdata = new FormData();
    // fdata.append('email', email);
    // fdata.append('contactNo', mobile);
    // const config = {
    //     method: 'post',
    //     maxBodyLength: Infinity,
    //     url: `${url}/api/app/users/verifyEmailContact`,

    //     data: fdata
    // }
    // const res = await axios.request(config);
    const response = await axios.post(`${url}/api/app/users/verifyEmailContact`, {
        "email": email,
        "contactNo": mobile,
    }, {
        headers: {
            'Content-Type': 'application/json'
        }
    });
    return response.data;
}

export const get_all_profiles = async (token) => {
    try {
        const response = await axios.post(`${url}/api/app/home/getLatestProfile`,
            {
                "startIndex": 0,
                "fetchRecord": 100000
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
export const send_proposel = async (id, token) => {
    try {
        const response = await axios.post(`${url}/api/app/proposals/sendProposals`,
            {
                "proposalUserId": id
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
export const get_my_proposels = async (token) => {
    getProposalsGotByMe(token)
    try {
        const response = await axios.post(`${url}/api/app/proposals/getProposalsSendByMe`,
            {
                "startIndex": 0,
                "fetchRecord": 2000
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
export const getProposalsGotByMe = async (token) => {
    try {
        const response = await axios.post(`${url}/api/app/proposals/getProposalsGotByMe`,
            {
                "startIndex": 0,
                "fetchRecord": 2000
            },
            {
                headers: {
                    'Authorization': `Bearer ${token}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}


export const logout = () => {

    localStorage.clear();
    return true;
}

export const get_latest_profiles = async () => {
    try {
        const response = await axios.post(`${url}/api/app/home/getLatestProfile`,
            {
                "startIndex": 0,
                "fetchRecord": 100000
            },
            {
                headers: {
                    'Authorization': `Bearer ${mtoken}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}

export const get_nearest_profiles = async () => {
    try {
        const response = await axios.post(`${url}/api/app/users/getNearestApplicant`,
            {
                "startIndex": 0,
                "fetchRecord": 100000
            },
            {
                headers: {
                    'Authorization': `Bearer ${mtoken}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
export const get_most_viewed_profiles = async () => {
    try {
        const response = await axios.post(`${url}/api/app/users/getMostViewedApplicant`,
            {
                "startIndex": 0,
                "fetchRecord": 100000
            },
            {
                headers: {
                    'Authorization': `Bearer ${mtoken}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
export const validate_auth_token = async () => {
    try {
        const response = await axios.post(`${url}/api/app/users/validateAuthToken`,
            {},
            {
                headers: {
                    'Authorization': `Bearer ${mtoken}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
export const cities = async () => {
    try {
        const response = await axios.post(`${url}/api/app/regions/getCities`,
            {
                "startIndex": 0,
                "fetchRecord": 100000
            },
            {
                headers: {
                    'Authorization': `Bearer ${mtoken}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
export const countries = async () => {
    try {
        const response = await axios.post(`${url}/api/app/regions/getCountries`,
            {
                "startIndex": 0,
                "fetchRecord": 100000
            },
            {
                headers: {
                    'Authorization': `Bearer ${mtoken}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}
export const update_my_profile = async (data) => {
    try {
        const response = await axios.post(`${url}/api/app/users/updateUserProfileDetail`,
            data,
            {
                headers: {
                    'Authorization': `Bearer ${mtoken}`
                }
            });
        return response.data;
    } catch (error) {
        console.log(error)
    }
}