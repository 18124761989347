import PageBanner from 'layouts/PageBanner.jsx'
import React from 'react'

const About = () => {
  return (
    <>
        <PageBanner title="About Us"/>
    </>
  )
}

export default About
