
import PageBanner from 'layouts/PageBanner.jsx'
import React, { useEffect, useState } from 'react'
import { Form, Modal } from 'react-bootstrap';
import check from "../../assets/images/check.jpg"
import { useNavigate } from 'react-router-dom';

function DeletePolicy() {

    const [show, setshow] = useState(false)
    const handleClose = () => setshow(false);
    const [popup, setpopup] = useState(false)
    const navigate = useNavigate()




    const handlesubmit = (e) => {
        e.preventDefault()
        setshow(true)
        setTimeout(() => {
            setshow(false)
            navigate('/')

        }, 1000);
    }

    return (
        <>

            <PageBanner title="Delete Policy" />
            <section className='py-5 policycontent'>


                {/* <div className="container">
                    <div className="row">
                        <div class="col-md-12">
                            <h3>Profile Deletion Request</h3>
                            <p>
                                Your privacy and data security are important to us. If you no longer wish to maintain an account with Surajmal Memorial Education Society, you can request the deletion of your profile at any time.
                            </p>
                            <h3>How to Delete Your Profile</h3>
                            <p>
                                To delete your profile, please send an email to
                                <a href="mailto:matrimonysmes@msijanakpuri.com">matrimonysmes@msijanakpuri.com</a>
                                with the subject line "Delete Profile Request". In the body of the email, kindly include your registered email address and any relevant information to help us identify your profile.
                                Our team will process your request and delete your profile within 5 business days.
                            </p>
                            <h3>What Happens When You Delete Your Profile</h3>
                            <p>
                                Once your profile is deleted, all of your personal information, including any associated data, will be permanently removed from our system. This action is irreversible, and you will not be able to recover your account or any data associated with it after deletion.
                            </p>
                            <h3>Additional Assistance</h3>
                            <p>
                                If you experience any issues or have any questions about deleting your profile, please feel free to reach out to our support team by emailing us at
                                <a href="mailto:matrimonysmes@msijanakpuri.com">matrimonysmes@msijanakpuri.com</a>.
                                We are here to assist you with any concerns you may have regarding your account or data privacy.
                            </p>

                        </div>
                    </div>
                </div> */}


                <div className="container">

                    <Form onSubmit={(e) => handlesubmit(e)}>
                        <div className="row gy-3">
                            <div className="col-md-12">
                                <h3 className='text-center'>Delete Account Form</h3>
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="">Name <span className='text-danger'>*</span></label>
                                <input type="text" className='form-control' placeholder='Name' required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="">Email <span className='text-danger'>*</span></label>
                                <input type="text" className='form-control' placeholder='Email' required />
                            </div>
                            <div className="col-md-6">
                                <label htmlFor="">Phone </label>
                                <input type="text" placeholder='Phone' className='form-control' />
                            </div>

                            <div className="col-md-12">
                                <label htmlFor="">Reason <span className='text-danger'>*</span>  </label>
                                <textarea name="" required style={{ height: "150px" }} placeholder='Why Are You Delete Account ?' className='form-control' id=""></textarea>
                            </div>
                            <div className="col-md-12">
                                <button className='btn  text-white px-5' style={{ background: "#fb846f" }}>Submit</button>
                            </div>
                        </div>

                    </Form>
                </div>
            </section >



            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                size='md'
                centered
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>{"Account Deletion Confirmation"}</Modal.Title>

                </Modal.Header>
                <Modal.Body>
                    <div className="text-center">
                        <div className="d-flex justify-content-center mb-4">
                            <img src={check} className="img-fluid" style={{ height: "100px", width: "100px" }} alt="Success" />
                        </div>
                        <h5 style={{ color: "#fb846f" }}>Your account will be deleted after 2 days.</h5>
                    </div>

                </Modal.Body>

            </Modal>


        </>
    )
}

export default DeletePolicy