// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";



// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyAi8iSiUoIHgDrU9ct4nowfW5WwXsirb5I',
  appId: '1:540787653314:web:07f1caa6a492e2807f995b',
  messagingSenderId: '540787653314',
  projectId: 'surajmalmatrimoni',
  authDomain: 'surajmalmatrimoni.firebaseapp.com',
  storageBucket: 'surajmalmatrimoni.appspot.com',
  measurementId: 'G-J80YHDCTBE',
}


// Initialize Firebase
const app = initializeApp(firebaseConfig);

