import Header from 'layouts/MainLayout.jsx/Header'
import PageBanner from 'layouts/PageBanner.jsx'
import React from 'react'

function Terms() {
  return (
    <>

      <PageBanner title="Terms & Conditions" />
      <section className='py-5 policycontent bg-odd'>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="w-100">
                <h3>1. Acceptance of Terms</h3>
                <p>By using the Suraj Sujan Matrimony website, you agree to comply with and be bound by these Terms and Conditions. If you do not agree with these terms, please do not use our services.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>2. Eligibility</h3>
                <p>You must be at least 18 years old to use our services. By registering on our website, you confirm that you meet this age requirement.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>3. User Account</h3>
                <p>When you create an account on Suraj Sujan Matrimony, you agree to provide accurate and complete information. You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>4. Use of Services</h3>
                <p>You agree to use our services only for lawful purposes. You are prohibited from using our platform to engage in any illegal activities, including but not limited to fraud, harassment, or any behavior that violates the rights of others.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>5. Content Ownership</h3>
                <p>All content provided on the Suraj Sujan Matrimony website, including text, graphics, logos, and images, is the property of Suraj Sujan Matrimony and is protected by intellectual property laws. You may not reproduce, distribute, or create derivative works based on our content without our prior written permission.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>6. User-Generated Content</h3>
                <p>You are solely responsible for the content you upload or share on our platform. By posting content, you grant Suraj Sujan Matrimony a non-exclusive, royalty-free, worldwide license to use, display, and distribute your content in connection with our services.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>7. Privacy Policy</h3>
                <p>Your use of our services is also governed by our <a href="privacy-policy.html">Privacy Policy</a>. Please review the Privacy Policy to understand our practices regarding your personal information.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>8. Termination of Account</h3>
                <p>We reserve the right to terminate or suspend your account at our discretion, without notice, if we believe that you have violated these Terms and Conditions or engaged in any unauthorized activity.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>9. Limitation of Liability</h3>
                <p>Suraj Sujan Matrimony is not responsible for any damages, losses, or liabilities arising from your use of our services. We do not guarantee the accuracy, reliability, or availability of our platform and are not liable for any interruptions or errors.</p>

              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>10. Indemnification</h3>
                <p>You agree to indemnify and hold harmless Suraj Sujan Matrimony, its affiliates, and employees from any claims, damages, or expenses arising from your use of our services or your violation of these Terms and Conditions.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>11. Changes to These Terms</h3>
                <p>We may update these Terms and Conditions from time to time. Any changes will be posted on this page, and the updated terms will take effect upon posting. Your continued use of our services constitutes your acceptance of the revised terms.</p>
              </div>
            </div>
            <div className="col-md-12">
              <div className="w-100">
                <h3>13. Contact Us</h3>
                <p><strong>Suraj Sujan Matrimony</strong></p>
                <p>4789 Melmorn Street, Zakila Ton, Mashintron Town</p>
                <p>Phone: (+01) 123 456 7890</p>
                <p> Email: <a href="mailto:matrimonysmes@msijanakpuri.com">matrimonysmes@msijanakpuri.com</a></p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>



  )
}

export default Terms