import { logout } from "utils";

const { faHomeAlt, faRing, faHeart, faPowerOff, faUser } = require("@fortawesome/free-solid-svg-icons");
const { FontAwesomeIcon } = require("@fortawesome/react-fontawesome");
const { Link } = require("react-router-dom");
const handlelogout  = () => {
    logout();
   window.location.href = "/"
        
}
export const navlist = (
    
    <>
        <ul className="mb-0 list-unstyled dashboardlinks">
            <li>
                <Link to={'/dashboard'} className="nav-link">
                    <span className="icon">
                        <FontAwesomeIcon icon={faHomeAlt} />
                    </span>
                    <span className="text">
                        Dashboard
                    </span>
                </Link>
            </li>
            <li>
                <Link to={'/profile'} className="nav-link">
                    <span className="icon">
                        <FontAwesomeIcon icon={faUser} />
                    </span>
                    <span className="text">
                        My Profile
                    </span>
                </Link>
            </li>
            <li>
                <Link  to={'/proposels-to-me'} className="nav-link">
                    <span className="icon">
                        <FontAwesomeIcon icon={faRing} />
                    </span>
                    <span className="text">
                        Proposal To Me
                    </span>
                </Link>
            </li>
            <li>
                <Link to={'/proposels'} className="nav-link">
                    <span className="icon">
                        <FontAwesomeIcon icon={faHeart} />
                    </span>
                    <span className="text">
                        Proposal By Me
                    </span>
                </Link>
            </li>
            <li>
                <Link onClick={handlelogout} className="nav-link">
                    <span className="icon">
                        <FontAwesomeIcon icon={faPowerOff} />
                    </span>
                    <span className="text">
                        Logout
                    </span>
                </Link>
            </li>
        </ul>
    </>
)