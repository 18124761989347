import React from 'react'
import aboutimg from 'assets/images/about.png'
import screen2 from "assets/images/couples/screen2.jpeg"
import screen3 from "assets/images/couples/screen3.jpeg"


const About = () => {
    return (
        <>
            <section className=' py-5 aboutSection bg-odd'>
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h2 className='section_label'>
                                Introducing  each other
                            </h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-7">
                            <div className="w-100 imagebox position-relative">
                                <img src={screen2} alt="" className="img-fluid rounded-3" style={{ height: "400px" }} />
                                <img src={screen3} alt="" className="img-fluid rounded-3" style={{ height: "300px", position: "absolute", top: "30%", left: "50%" }} />
                            </div>
                        </div>
                        <div className="col-md-5">
                            <div className="w-100 textbox p-3">

                                <h4>Choose by Interests, Location and other relevant filters</h4>
                                <p>
                                    Customize your partner search with filters like Hobbies, Location and more. Find your suitable match!
                                </p>
                                <div className="d-flex gap-3">
                                    <button className="btn btn-custom">
                                        <div className="w-100 py-2 px-3 rounded-1">Read More</div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default About
