import React from 'react'
import Banner from './Banner'
import Features from './Features'
import About from './About'

import Slider from "react-slick";
import imgone from 'assets/images/couples/img-1.jpg'
import Cta from './Cta';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft } from '@fortawesome/free-solid-svg-icons';
import { requestForToken } from 'firebase_setup/firebaseConfig';

const Home = () => {

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "60px",
    slidesToShow: 1,
    speed: 500
  };
  return (
    <>
      <Banner />
      <Features />
      <About />
      {/* <section className="space">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h2 className="section_label">
                Thousands of Jat Matrimonial Matches Available
              </h2>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="w-100">
                <div className="slider-container">
                  <Slider {...settings}>
                    {
                      [...Array(8)].map((ar, i) => (
                        <>
                          <div key={i * 2} className='w-100 p-3'>
                            <div className="w-100  bg-odd p-4  border-danger rounded-3 suraj_story">
                              <div className="row align-items-center">
                                <div className="col-md-7">
                                  <div className="w-100 story_text">
                                    <p>
                                      <FontAwesomeIcon icon={faQuoteLeft} />
                                      "Soumya Sankar and Arjun Madhu, a happy-go-lucky couple connected with each other through Suraj Sujan Matrimony. Soumya was looking for someone who was polite, disciplined and had liberal values. Since she was not very outspoken, she wanted someone with similar characteristics. Arjun sent the first interest to Soumya, and her.
                                      a happy-go-lucky couple connected with each other through Suraj Sujan Matrimony. Soumya was looking for someone who was polite, disciplined and had liberal values. Since she was not very outspoken, she wanted someone with similar characteristics. Arjun sent the first interest to Soumya, and her.
                                    </p>
                                    <h4>Soumya Sankar & Arjun Madhu</h4>
                                  </div>

                                </div>
                                <div className="col-md-5">
                                  <div className="w-100 position-relative">
                                    <img src={imgone} alt="" className="img-fluid rounded-4" />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    }


                  </Slider>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      <Cta />
    </>
  )
}

export default Home
