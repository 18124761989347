import About from "pages/about";
import Login from "pages/auth/Login";
import Contact from "pages/contact";
import DeletePolicy from "pages/policy/DeletePolicy";
import PrivacyPolicy from "pages/policy/PrivacyPolicy";
import Refund from "pages/policy/Refund";
import Terms from "pages/policy/Terms";

const { default: MainLayout } = require("layouts/MainLayout.jsx");
const { default: Home } = require("pages/Home");

const MainRoutes = {
    path: "/",
    element: <MainLayout />,
    children: [
        {
            path: "/",
            element: <Home />
        },
        {
            path: "/about",
            element: <About />
        },
        {
            path: "/contact",
            element: <Contact />
        },
        {
            path: "/privacy-policy",
            element: <PrivacyPolicy />
        },
        {
            path: "/terms-conditions",
            element: <Terms />
        },
        {
            path: "/refund-cancellation",
            element: <Refund />
        },
        {
            path: "/delete-policy",
            element: <DeletePolicy />
        },
        {
            path: "/login",
            element: <Login />
        }
    ]

};
export default MainRoutes;